import { Pipe, PipeTransform } from "@angular/core";
import {
	ITimeSlotRequest,
	TimeSlot,
} from "@sportyano/core/models/programs/time-slots.model";
import { SCHEDULE_SLOT_STATE } from "@sportyano/shared/components/calendar-scheduler/models/const/schedule-slot.const";
import { ISlotStatus } from "@sportyano/shared/components/calendar-scheduler/models/interfaces/schedule-slot.interface";
import { DateTimeUtils } from "@sportyano/shared/utilis/date-time-utilits";

@Pipe({
	name: "checkSlotAvailability",
	standalone: true,
})
export class CheckSlotAvailability implements PipeTransform {
	transform(
		slotData: { date: string; time: string },
		slots: Map<string, TimeSlot[]>,
		localBookedSlots: Map<string, ITimeSlotRequest>,
		timeRange: { from: string; to: string }
	): ISlotStatus {
		const _slotTime = DateTimeUtils.convertTo24Hour(slotData.time);
		const concatValue = slotData.date.concat(" ", _slotTime);
		// Split the "HH:MM" format into hours
		let [fromHour] = timeRange.from.split(":").map(Number);
		const [toHour] = timeRange.to.split(":").map(Number);
		// Get the current date and time
		const currentDateTime = new Date();
		const currentDate = currentDateTime.toISOString().split("T")[0]; // Get the current date in YYYY-MM-DD format

		// Add one hour to the current time
		currentDateTime.setHours(currentDateTime.getHours() + 1);
		const currentTime = DateTimeUtils.convertTo24Hour(
			currentDateTime.toLocaleTimeString([], {
				hour: "2-digit",
				minute: "2-digit",
			})
		);

		// Check if the requested date is today and if the time has passed
		if (slotData.date === currentDate && _slotTime < currentTime) {
			if (
				fromHour > toHour &&
				0 < _slotTime.split(":").map(Number)[0] &&
				_slotTime.split(":").map(Number)[0] >= fromHour
			) {
				return {
					slot: null,
					state: SCHEDULE_SLOT_STATE.disabled,
				};
			}

			if (fromHour < toHour) {
				return {
					slot: null,
					state: SCHEDULE_SLOT_STATE.disabled,
				};
			}
		}

		// Check in local booked slots first
		if (localBookedSlots.has(concatValue)) {
			return {
				slot: localBookedSlots.get(concatValue) as unknown as TimeSlot,
				state: SCHEDULE_SLOT_STATE.selected,
			};
		}

		// Check in the response slots
		if (slots.has(slotData.date)) {
			const slot = slots.get(slotData.date)?.find((slot) => {
				const [slotDate, slotTime] = slot.slot.split(" ");
				return slotDate === slotData.date && slotTime === _slotTime;
			});

			if (slot) {
				const state =
					slot.status === 1
						? SCHEDULE_SLOT_STATE.available
						: SCHEDULE_SLOT_STATE.booked;

				return {
					slot: slot,
					state: state,
					is_booked: slot.is_booked,
				};
			}
		}

		// Return available status if the slot is not found
		return {
			slot: null,
			state: SCHEDULE_SLOT_STATE.available,
		};
	}
}
