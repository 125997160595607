import { Pipe, PipeTransform } from "@angular/core";
import {
	ITimeSlotRequest,
	TimeSlot,
} from "@sportyano/core/models/programs/time-slots.model";
import { DateTimeUtils } from "@sportyano/shared/utilis/date-time-utilits";

@Pipe({
	name: "CheckSlotSelected",
	standalone: true,
})
export class CheckSlotSelected implements PipeTransform {
	transform(
		slotData: string,
		localBookedSlots: Map<string, ITimeSlotRequest>
	): boolean {
		const [slotDate, slotTime] = slotData.split(" ");

		return localBookedSlots.has(slotData);
	}
}
